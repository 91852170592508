/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-class-component-methods */
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from 'lodash';
import Router from "next/router";

import { checkSession, deactivateSession, setLogoutActivity } from '../slices/authSlice';
import { keepReferrerInBrowser } from '../utils/returnToHandler';
import IdleTimer from '../../../shared_components/IdleTimer';
import { loginPath } from '../../../client_routes';
import { setIsLogoutSent } from "../slices/usersActivitySlice";

const idleMinutes = process.env.NEXT_PUBLIC_IDLE_MINUTES ? Number(process.env.NEXT_PUBLIC_IDLE_MINUTES) : 5;

const withSession = (Page, pageName = '') => {
  class SessionPage extends Component {
    constructor(props) {
      super(props);

      const path = props.router.asPath;

      this.state = {
        beforeInactiveActions: [],
        referrer: (pageName === 'idleSession') ? props.auth.referrer : path,
      };

      this.idleTimer = null;
      this.onIdle = this._onIdle.bind(this);
      this.captureBeforeInactiveAction = this.captureBeforeInactiveAction.bind(this);
      this.removeBeforeInactiveAction = this.removeBeforeInactiveAction.bind(this);
    }

    componentDidMount() {
      this.checkSessionToken(true)
    }

    componentDidUpdate() {
      this.checkSessionToken()
    }

    async _onIdle() {
      const { beforeInactiveActions, referrer } = this.state;
      const { deactivateSession, auth: { currentStaff }, usersActivity: { isLogoutActivitySent }, setIsLogoutSent, setLogoutActivity} = this.props;
      if (!_.isEmpty(beforeInactiveActions)) {
        await Promise.all(beforeInactiveActions.map(({ action }) => action()));
        this.setState({ beforeInactiveActions: [] });
      }
      if (!isLogoutActivitySent) {
        const staffName = `${currentStaff?.firstName || ''} ${currentStaff?.lastName || ''}`;
        setLogoutActivity({
          description: `Staff ${staffName} was logged out after the system had been idle for a period of time without any activity.`,
          isOnIdle: true,
        });
        setIsLogoutSent();
      }
      deactivateSession(referrer);
      // eslint-disable-next-line no-undef
      FreshworksWidget('close');
      Router.replace('/switch-user');
    }

    captureBeforeInactiveAction(type, action) {
      this.setState(({ beforeInactiveActions }) => ({
        beforeInactiveActions: [
          ...beforeInactiveActions,
          { type, action },
        ],
      }));
    }

    removeBeforeInactiveAction(type) {
      const { beforeInactiveActions } = this.state;
      const restActions = beforeInactiveActions.filter((action) => {
        return action.type !== type;
      });

      this.setState({ beforeInactiveActions: restActions });
    }

    checkSessionToken(serverCheck) {
      const { referrer } = this.state;
      const { auth: { token, isSessionIdle, currentStaff }, checkSession, setLogoutActivity, usersActivity: { isLogoutActivitySent } } = this.props;

      if (!token) {
        keepReferrerInBrowser(referrer);
        if (!isLogoutActivitySent) {
          const staffName = `${currentStaff?.firstName || ''} ${currentStaff?.lastName || ''}`;
          setLogoutActivity({
            description: `Staff ${staffName} was logged out after the session time expired.`,
            isOnSession: true,
          });
          setIsLogoutSent();
        }
        Router.replace(loginPath());
        return;
      } else if (isSessionIdle) {
        Router.replace('/switch-user');
        return;
      }

      if (serverCheck) {
        checkSession();
      }
    }

    render() {
      const { auth: { token } } = this.props;

      return (token && (
        <>
          <Page
            {...this.props}
            captureBeforeInactiveAction={this.captureBeforeInactiveAction}
            removeBeforeInactiveAction={this.removeBeforeInactiveAction}
          />

          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            onIdle={this.onIdle}
            timeout={idleMinutes * 60 * 1000}
          />
        </>
      ));
    }
  }

  const mapStateToProps = ({ auth, business, usersActivity }) => ({ auth, business, usersActivity });

  const mapDispatchToProps = dispatch => ({
    checkSession: bindActionCreators(checkSession, dispatch),
    deactivateSession: bindActionCreators(deactivateSession, dispatch),
    setLogoutActivity: bindActionCreators(setLogoutActivity, dispatch),
    setIsLogoutSent: bindActionCreators(setIsLogoutSent, dispatch),
  });

  return connect(mapStateToProps, mapDispatchToProps)(SessionPage);
};

export default withSession;
